import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesTrony: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Trony guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_trony.webp"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Trony guide & review</h1>
          <h2>A guide & review for Trony in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>18/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Trony" />
        <StaticImage
          src="../../../images/nikke/generic/banner_trony.webp"
          alt="Trony"
        />
        <p>
          <strong>Trony</strong> banner will be available:{' '}
          <strong>5/16 After Maintenance ~ 5/30 04:59 (UTC+9)</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="trony" enablePopover />
        </div>
        <p>
          Trony is a Fire B3 Attacker designed for feet lovers. Period. But,
          whether you agree with that subjective claim, it is unquestionable
          that Trony's enticing appearance has indeed sparked joy. She attacks
          enemies with the help of her special Cumulative Damage debuff, which
          acts as her source of bonus damage. By converting some of her damage
          dealt, she can conveniently multiply her output, inflicting
          distributed damage and also gaining a personal ATK buff from time to
          time.
        </p>
        <SectionHeader title="Summary" />
        <h5>What do you think about her?</h5>
        <p>
          Her cost-effectiveness has much left to be desired. While she can
          compete Scarlet (against Fire Element Weak Bosses) when heavily
          invested, she has a high skill ceiling and costs thousands more burst
          manuals to be usable. Even Scarlet is barely in meta right now, and
          her unsupported DPS is already outclassed by many. Trony's damage also
          does not scale with the number of enemies and is stagnant, unlike
          Scarlet with her legendary AoE wipe. Unless Elemental Type is a major
          issue, or ShiftUp creates a situation that favors her Cumulative
          Damage playstyle, her presence in the meta will be limited.
        </p>
        <h5>Should I pull her?</h5>
        <p>
          Nope. Her DPS is just disappointing seeing the level of investments
          needed to develop her. We recommend SKIPPING this unit, unless you
          like feet, in which case we wholeheartedly recommend getting MLB for
          that spicy lobby screen.{' '}
          <i>
            Disclaimer: We are not responsible for any poor financial decisions
            that you make to satisfy your carnal desires.
          </i>
        </p>
        <SectionHeader title="Kit Analysis & Highlights" />
        <StaticImage
          src="../../../images/nikke/generic/kit_trony.webp"
          alt="Trony"
        />
        <p>
          Trony's gimmicky skillset is actually very simple once we understand
          how it works. In fact, it is too simple that her real performance does
          not in any way justify those lengthy descriptions.
        </p>
        <h5>Dorothy At Home</h5>
        <StaticImage
          src="../../../images/nikke/generic/trony_review_1.webp"
          alt="Trony"
        />
        <p>
          Remember Brand? Clearly you do, because you couldn't have possibly
          forgotten our Goddess Doro. Pretty much Trony's S1 is just a weaker
          version of Brand whose damage pool can only be filled by Trony
          herself. Similarly, its maximum cap is determined the second this
          debuff is initialized based on Trony's final ATK at that moment (other
          DMG buffs and debuffs discounted). However, unlike Brand, its time
          limit of 5s is <strong>not fixed</strong>, which means once it reaches
          its maximum cap, the damage is transferred immediately and a new cycle
          can begin. If a mob with this debuff dies, the damage is still
          distributed to nearby mobs.
        </p>
        <p>
          Only one instance of Cumulative Damage debuff can be applied at a
          time. If Mob A has that, then you cannot apply it on Mob B, and you
          must wait till it expires or explodes. Before then, you should ideally
          keep shooting at Mob A for the damage buff. Otherwise, Trony will also
          lose all damage buff. Treat it as <strong>target lock</strong>.
        </p>
        <blockquote>
          <p>
            <strong>For the mathematically inclined:</strong>
          </p>
          <ul>
            <li>AI can full charge 3 times within 5 seconds.</li>
            <li>
              On manual, you can full charge 4 times with tight timings @ 1170 +
              17ms per shot.
            </li>
            <li>
              Influenced by ATK buffs that are provided at the start of Full
              Burst(s), such as Maxwell's and Privaty's.
            </li>
            <li>
              The explosion of S1, when reaching maximum cap or expiring, grants
              2.8% burst energy.
            </li>
          </ul>
        </blockquote>
        <p>
          Since S1 can be applied repeatedly and instantly explode upon reaching
          its cap, it can be pretty much viewed as an ordinary damage boost,
          except for the initial shot that applies the debuff. Thus, we can
          assume that this translates to{' '}
          <strong>50% TDM (Total Damage Multiplier)</strong> on average. This
          value is taken from the base conversion ratio.{' '}
        </p>
        <Alert variant="primary">
          <p>
            Since the initial shot is not counted toward accumulated damage, the
            perceived TDM is actually slightly less than 50%. This will be worse
            when the cycles are shorter, since there will be more initial shots.
          </p>
        </Alert>
        <p>
          In short, Trony's S1 is her primary source of damage, which means that
          she will be dealing virtually no damage without it. Thus, instead of
          explaining S2 and Burst Skill separately, we will describe their
          relationship with S1 and how they affect each other.{' '}
        </p>
        <h5>Anti-Feet Gang</h5>
        <StaticImage
          src="../../../images/nikke/generic/trony_review_2.webp"
          alt="Trony"
        />
        <p>
          Let's start with her S2. Her S2 is not awful but useless. S2 grants
          Trony a buff that enhances her Distributed Damage, but what this
          basically does is not amplify the explosion damage but increase the
          maximum cap of S1. The problem is that her S1 can be reapplied as soon
          as it reaches its cap, so what is the use of a higher cap? To reduce
          the number of initial shot(s) we mentioned earlier & the occurrences
          of truncated conversions, but is it worth the skill manuals? Not
          really.
        </p>
        <p>
          <strong>
            Truncated Conversion: If the current accumulated damage is 800,000,
            and the cap is 1,000,000, and you deal 500,000 (× 50% = 250,000),
            then the last 50,000 damage will go to waste.
          </strong>
        </p>
        <Alert variant="primary">
          <p>
            Even if S2 is triggered <strong>after</strong> debuff is already
            applied, it will still increase the maximum cap. Also, since refresh
            time &lt; the buff duration, S2 can be considered permanent, unless
            you do not Full Charge all the time.
          </p>
        </Alert>
        <p>
          In fact, we discovered that she would deal more damage by mixing Full
          Charge(s) and quick scopes. Perform a Full Charge to apply Cumulative
          Damage debuff, then follow it up with quick scopes until you reach
          100%. Then, repeat the cycle. If you notice, this optimal playstyle is
          unconcerned about S2's effects!{' '}
        </p>
        <p>
          Last but not least, her S2 also features a DEF reduction that barely
          has any effect, but it still exists, so we need to bring it up for
          formality.
        </p>
        <h5>Pro-Feet Gang</h5>
        <p>
          In contrast to S2, Trony's Burst Skill plays an indispensable role in
          boosting her damage. It accelerates the accumulation speed of S1 by{' '}
          <strong>additively</strong> increasing the conversion ratio, which
          also boosts the perceived TDM (read "Dorothy At Home"). At max level,
          it will be <strong>50% + 62.83% = 112.83% (or 2.13x).</strong>
        </p>
        <p>
          Not only that, it also massively boosts Trony's ATK, and since Trony
          does not have any ATK buff outside Burst Skill, this buff will have a
          significant impact. ATK OLs will also greatly enhance her overall
          damage.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Tier List & Analysis" />
        <h5>
          Campaign (LD) - <strong className="c">C</strong>
        </h5>
        <p>
          As we mentioned earlier, initial shot(s) are RAW and do not benefit
          from the conversion TDM boost. Since campaign involves a lot of mob
          killing, you will have a lot of initial shot(s) and fewer TDM
          utilizations, making her no better than Eunhwa or Yulha DPS wise
          outside Burst.
        </p>
        <h5>
          Campaign (HD) - <strong className="c">C</strong>
        </h5>
        <p>
          Due to stat penalty, mobs will become tankier, and you can now start
          utilizing the conversion TDM boost. However, Trony's kit lacks
          consistent ATK buff that would make this worthwhile. This would make
          her similar to Alice in playstyle, where she will only pop during
          Burst. But due to the insane DPS output of Alice as well as her having
          pierce, these characters are not even comparable. Trony is not an
          ideal unit to use in Campaign.
        </p>
        <h5>
          Boss (Solo) - <strong className="b">B</strong>
        </h5>
        <p>
          On Fire Weak Bosses, Trony's DPS can rival that of Scarlet, but she
          has too many shortcomings. Expensive and requires manual gameplay for
          maximum damage. Theoretically, she would be a decent temporary
          replacement if you don't have better units, but do know that you might
          want to reset her in the future since she is simply not meta enough to
          fit in. If you do not have 2B, and there are Elemental QTE Shields,
          she can sub in as decent unit for Fire Weak Bosses in Solo Raids.
        </p>
        <h5>
          Boss (Adds) - <strong className="b">B</strong>
        </h5>
        <p>
          S1 can only be applied to one enemy at once, which means Trony should
          not change target for 5s after application, or her DPS would plunge.
          Unfortunately, against bosses with adds, you may sometimes need to
          switch target abruptly. Also, she has no AoE to wipe mobs, and in
          fact, those extra mobs may reduce damage dealt to the boss because of
          Distributed Damage.
        </p>
        <h5>
          PVP - <strong className="c">C</strong>
        </h5>
        <p>
          Target switches may make Trony shoot NIKKEs other than the debuffed
          one, losing damage in the process. No damage outside Burst. No AoE.
          The 5s window also takes too long to pop. In PvP, she is no better
          than most SR units.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          As per the usual standard, investing in a DPS requires OL-ing and
          enchanting Helmet and Gloves to Level 5, followed by Body Armor. For
          the OL, we recommend:
        </p>
        <ul>
          <li>
            <strong>4x ATK</strong>, since her kit lacks ATK outside Burst. High
            priority.
          </li>
          <li>
            <strong>4x ELEMENTAL</strong>, because there is nothing else better.
            High priority since her major use in Meta will be in Fire Weak
            Raids.
          </li>
          <li>
            <strong>2x AMMO</strong>, for burst regen to expedite burst cycles
            and also to make her S1 easier to accumulate with quick scopes. High
            priority.
          </li>
          <li>
            <strong>2x MISCELLANOUS</strong>, which can be more Ammo, Charge
            SPD, Charge DMG, CRIT Rate, or CRIT DMG. CRIT is less favorable, but
            the difference should be insignificant in a real battle. Low
            priority.
          </li>
        </ul>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 4</strong>
          </li>
          <ul>
            <li>
              Upgrades are trivial because the conversion ratio is invariable,
              but they help reduce the number of initial shot(s) and truncated
              conversions. CP padder.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 4</strong>
          </li>
          <ul>
            <li>
              Trivial in most situations. Helps reduce the number of initial
              shot(s) and truncated conversions. CP padder.
            </li>
          </ul>
          <li>
            <strong>Burst: 7~10</strong>
          </li>
          <ul>
            <li>
              Upgrades grant more damage via higher conversion ratio. Ideally,
              this should be high, as this is the only skill that scales well.
              Besides that, we only recommend using her if you are planning to
              reset her skills in the future.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Suggested Progression Path</strong>
        </p>
        <div className="nikke-skills-tips">
          <div className="nikke-skills-row header">
            <div className="column character">&nbsp;</div>
            <div className="column suggestions pve">PVE Investment</div>
            <div className="column suggestions pvp">PVP Investment</div>
            <div className="column priority">Priority</div>
          </div>
          <div className="nikke-skills-row data">
            <NikkeSkill char="Trony" />
          </div>
        </div>
        <br /> <br />
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          Resilience or Bastion would do fine. We recommend Bastion Lv 7+ if you
          have exactly 1x Max Ammo, as the extra 3 shots will help with charging
          Burst Energy after Full Burst(s). Otherwise, we prefer Resilience
          because their uptime/downtime balance is more consistent, especially
          if you're running her with other Reload buffers like Crown or Privaty.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1: God Comp</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="trony" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">B3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This one is currently the god team, with insane amount of strongest
          buff possible, with decent heal. Mostly reserved for 2 best DPS, but
          you can always ignore meta and put Trony here. The reload buff helps
          Trony fast tap playstyle to fill as many S1 as possible, and the core
          hit buffs help her reach the S1 cap faster, allowing for more nukes
          during Burst!
        </p>
        <h5>Team #2: Old God Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="trony" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">B3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          The previous god team, only different with Tia instead of Crown and
          you can only use 2 B3 max here. By choosing this team, you may want to
          do slow charge with Trony, which lowers her dmg a bit compared to fast
          tap playstyle. However, Core hits allow her to do extremely high
          damage!
        </p>
        <h5>Team #3: Reload Support</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="trony" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="ludmilla-winter-owner"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team utilises 2 DPS that needs fast reload, 2 supporters rely on
          last bullet mechanic to trigger their main gimmick. It's a simple
          team, just don't expect huge damage compared to other 2 teams above. A
          similar team was seen being used with Drake instead of Trony during
          the Stormbringer Raid. However, Trony definitely provides an upgrade
          over Drake in this situation!
        </p>
        <h5>Team #4: Ammo Support</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="trony" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team provides basic ammo support to both Trony and Modernia
          courtesy of Noir! Since both DPS are fire based, this team can be used
          against Fire weak bosses in Raids!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>PVP</p>
          </div>
        </div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Scales well with ATK OL.</li>
                  <li>
                    Damage conversion as a sole multiplier is a unique mechanic,
                    and a Total Damage Multiplier!
                  </li>
                  <li>
                    Can compete for a spot in Solo Raids against Fire Weak
                    Bosses, especially if incomplete roster.
                  </li>
                  <li>Interesting shooting pose and wallpaper.</li>
                  <li>Feet.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Manual-intensive.</li>
                  <li>
                    Initial shot(s) and truncated conversion(s) are DPS loss and
                    are more rampant against squishy enemies.
                  </li>
                  <li>
                    Debuff can only be applied on one enemy at a time,
                    initiating Target Lock.
                  </li>
                  <li>
                    Lacks consistent ATK buff to pair with her constant S1
                    activation.
                  </li>
                  <li>Requires invested Burst Skill.</li>
                  <li>Skill 2 is almost useless.</li>
                  <li>Not cost-effective.</li>
                  <li>We have too many Fire DPS as of now.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesTrony;

export const Head: React.FC = () => (
  <Seo
    title="Trony guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Trony in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
